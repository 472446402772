import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import './buttonAnimation.css'

function ProductForm() {
    const [formData, setFormData] = useState({
        product_name: '',
        product_type: '',
        product_size: '',
        product_price: '',
        product_imageURLs: '',
        product_brand: '',
        isAvailable: ''
    });
    const [clicked, setClicked] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setClicked(true);
        setTimeout(() => setClicked(false), 400);
        const sizes = formData.product_size.split(/[;,]+/).map(item => item.trim());

        const imageUrls = formData.product_imageURLs.split(/[;,]+/).map(item => item.trim());

        // Construct the final form data to be sent
        const productData = {
            ...formData,
            product_size: sizes,
            product_imageURLs: imageUrls
        };

        try {
            const response = await fetch('https://product-data.yiungest.workers.dev/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(productData)
            });
            const result = await response.text();
            alert('Product Data Uploaded Successfully:' + result);
        } catch (error) {
            console.error('Error submitting data:', error);
            alert('Failed to upload product data, contact eleyi for any dificulties');
        }
    };

    return (
        <Container className='center p-5'>
            <div className='m-3  bold text-center'>
                <h1 className='display-2'>Update Product Data API</h1>
                <hr />
                <h5 className='text-danger'>Remember to tell Eleyi if you made any mistakes while uploading</h5>
                </div>
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Label><h6>Product Name</h6></Form.Label>
                <Form.Control
                    type="text"
                    name="product_name"
                    value={formData.product_name}
                    placeholder="product full name"
                    onChange={handleChange}
                        required autoComplete

                />
            </Form.Group>

            <Form.Group className="mb-3">
                    <Form.Label><h6>Product Type</h6></Form.Label>
                <Form.Control
                    type="text"
                    name="product_type"
                    value={formData.product_type}
                    onChange={handleChange}
                    placeholder='e.g, shoe, shirt'
                    required
                    autoComplete
                />
            </Form.Group>

            <Form.Group className="mb-3">
                    <Form.Label><h6>Product Sizes (separate with commas or semicolons)</h6></Form.Label>
                <Form.Control
                    type="text"
                    name="product_size"
                    value={formData.product_size}
                    onChange={handleChange}
                    placeholder="e.g., S; M; L; XL or 56,34,76"
                    required
                        autoComplete

                />
            </Form.Group>

            <Form.Group className="mb-3">
                    <Form.Label><h6>Product Price</h6></Form.Label>
                <Form.Control
                    type="text"
                    name="product_price"
                    value={formData.product_price}
                    placeholder='e.g., 20,000, 30,000'
                    onChange={handleChange}
                    required
                        autoComplete

                />
            </Form.Group>

            <Form.Group className="mb-3">
                    <Form.Label><h6>Product Image URLs (separate with commas',' or semicolons';')</h6></Form.Label>
                <Form.Control
                    type="text"
                    name="product_imageURLs"
                    value={formData.product_imageURLs}
                    onChange={handleChange}
                    placeholder="e.g., http://image1.jpg, http://image2.jpg"
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3">
                    <Form.Label><h6>Product Brand</h6></Form.Label>
                <Form.Control
                    type="text"
                    name="product_brand"
                    value={formData.product_brand}
                        placeholder='e.g., nike, weard, addidas' autoComplete

                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3">
                    <Form.Label><h6>Is this product available?</h6></Form.Label>
                <Form.Control
                text="text"
                name="isAvailable"
                value={formData.isAvailable}
                onChange={handleChange}
                placeholder='e.g., yes, no'
                required />
            </Form.Group>

                <Button variant="warning" type="submit" className={clicked ? 'button-clicked' : ''}>
                <b>Submit</b>
            </Button>
        </Form>
        </Container>
    );

}

export default ProductForm;