import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ProductForm from "./components/form";

function App() {
  return (
   <ProductForm />
  );
}

export default App;
